import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const HomeBannerImage = () => {

    return (
        <StaticImage src="../../images/home_banner.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="Home - Daniel Madden and Partners" quality="80" className="img-fluid banner-img" />
        // <img src={homebanner} alt="Home - Daniel Madden and Partners" className="img-fluid banner-img" />
    )

}

export default HomeBannerImage